<template>
  <a :href="link">
    <span class="view-more-button d-flex justify-content-between">
      <p class="ms-4">View More</p>
      <i class="bi bi-arrow-right mx-3"></i>
    </span>
  </a>
</template>

<script>
export default {
  name: "ViewMoreButton",
  props: {
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.view-more-button {
  font-family: "Cormorant Garamond", serif;
  width: 280px;
  height: 70px;
  line-height: 70px;
  font-size: 14px;
  color: #fff;
  background-color: #00417f;
  position: relative;
  display: inline-block;
  transition: color 0.25s ease;
  z-index: 1;

  &:hover {
    color: #fff;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #00274d;
    transform: scaleX(0);
    transform-origin: right;
    transition: all 0.5s ease;
    transition-property: transform;
    z-index: -1;
  }
}

@media screen and (max-width: 600px) {
  .view-more-button {
    width: 200px;
    height: 50px;
    line-height: 50px;
    font-size: 12px;
  }
}
</style>
