<template>
  <div>
    <div class="top-contact-heading">
      <h1>Contact</h1>
    </div>
    <div class="contactform-containr">
      <p class="contactform-text">
        下記のフォームに必要項目を入力の上、「送信内容を確認」ボタンを押してください。
      </p>
      <form @submit.prevent="handleSubmit">
        <p class="error" v-if="errors.inquiryType">
          {{ errors.inquiryType }}
        </p>
        <FormItem name="お問い合わせ内容の種類" required="true">
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input
                type="radio"
                id="trade"
                name="inquiryType"
                value="trade"
                v-model="formData.inquiryType"
                checked
              />
              <label for="trade">お取引について</label>
            </div>
            <div class="checkbox-item">
              <input
                type="radio"
                id="recruitment"
                name="inquiryType"
                value="recruitment"
                v-model="formData.inquiryType"
              />
              <label for="recruitment">採用について</label>
            </div>
            <div class="checkbox-item">
              <input
                type="radio"
                id="others"
                name="inquiryType"
                value="others"
                v-model="formData.inquiryType"
              />
              <label for="others">その他</label>
            </div>
          </div>
        </FormItem>

        <p class="error" v-if="errors.companyName">
          {{ errors.companyName }}
        </p>
        <FormItem name="会社名" required="true">
          <input
            class="textbox"
            type="text"
            name="company-name"
            v-model="formData.companyName"
          />
        </FormItem>

        <p class="error" v-if="errors.affiliation">
          {{ errors.affiliation }}
        </p>
        <FormItem name="部署名" required="true">
          <input
            class="textbox"
            type="text"
            name="affiliation"
            v-model="formData.affiliation"
          />
        </FormItem>

        <p class="error" v-if="errors.name">{{ errors.name }}</p>
        <FormItem name="お名前" required="true">
          <input
            class="textbox"
            type="text"
            name="name"
            v-model="formData.name"
          />
        </FormItem>

        <p class="error" v-if="errors.email">{{ errors.email }}</p>
        <FormItem name="メールアドレス" required="true">
          <input
            class="textbox"
            type="email"
            name="email"
            v-model="formData.email"
          />
        </FormItem>

        <p class="error" v-if="errors.phoneNumber">
          {{ errors.phoneNumber }}
        </p>
        <FormItem name="電話番号" required="true">
          <input
            class="textbox"
            type="tel"
            name="phone-number"
            placeholder="ハイフンなし"
            v-model="formData.phoneNumber"
          />
        </FormItem>

        <p class="error" v-if="errors.message">{{ errors.message }}</p>
        <FormItem name="お問い合わせ内容" required="true">
          <textarea
            class="textbox"
            name="message"
            v-model="formData.message"
          ></textarea>
        </FormItem>

        <p class="error" v-if="errors.consent">{{ errors.consent }}</p>
        <FormItem name="個人情報の取り扱い" required="true">
          <div class="checkbox-container">
            <div class="checkbox-item">
              <input
                type="checkbox"
                name="consent"
                v-model="formData.consent"
              />
              <label>
                <a href="#/privacypolicy" target="_blank"
                  >「個人情報の取り扱いについて」</a
                >に同意する
              </label>
            </div>
          </div>
        </FormItem>
        <input class="nextbutton" type="submit" value="送信内容を確認" />
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import FormItem from "../atoms/FormItem.vue";
import { useRouter } from "vue-router";

export default {
  name: "ContactPage",
  components: {
    FormItem,
  },

  setup() {
    const router = useRouter();
    const formData = reactive({
      inquiryType: "trade", // 初期値を文字列で設定
      companyName: "",
      affiliation: "",
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      consent: false,
    });

    const errors = reactive({
      inquiryType: "",
      companyName: "",
      affiliation: "",
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      consent: "",
    });

    const validateEmail = (email) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
      return /\d+/.test(phoneNumber);
    };

    watch(
      () => formData.email,
      (newEmail) => {
        if (!newEmail) {
          errors.email = "メールアドレスを入力してください";
        } else if (!validateEmail(newEmail)) {
          errors.email = "正しいメールアドレスを入力してください";
        } else {
          errors.email = "";
        }
      }
    );

    watch(
      () => formData.phoneNumber,
      (newPhoneNumber) => {
        if (!newPhoneNumber) {
          errors.phoneNumber = "電話番号を入力してください";
        } else if (!/^\d{10,11}$/.test(newPhoneNumber)) {
          errors.phoneNumber =
            "電話番号は10桁または11桁の数字で入力してください";
        } else if (!validatePhoneNumber(newPhoneNumber)) {
          errors.phoneNumber = "正しい電話番号を入力してください";
        } else {
          errors.phoneNumber = "";
        }
      }
    );

    const validateForm = () => {
      let isValid = true;
      if (!formData.inquiryType) {
        errors.inquiryType = "お問い合わせ内容を選択してください";
        isValid = false;
      } else {
        errors.inquiryType = "";
      }

      if (!formData.companyName) {
        errors.companyName = "会社名を入力してください";
        isValid = false;
      } else {
        errors.companyName = "";
      }

      if (!formData.affiliation) {
        errors.affiliation = "部署名を入力してください";
        isValid = false;
      } else {
        errors.affiliation = "";
      }

      if (!formData.name) {
        errors.name = "お名前を入力してください";
        isValid = false;
      } else {
        errors.name = "";
      }

      if (!formData.email) {
        errors.email = "メールアドレスを入力してください";
        isValid = false;
      } else if (!validateEmail(formData.email)) {
        errors.email = "正しいメールアドレスを入力してください";
        isValid = false;
      } else {
        errors.email = "";
      }

      if (!formData.phoneNumber) {
        errors.phoneNumber = "電話番号を入力してください";
        isValid = false;
      } else if (!validatePhoneNumber(formData.phoneNumber)) {
        errors.phoneNumber = "正しい電話番号を入力してください";
        isValid = false;
      } else {
        errors.phoneNumber = "";
      }

      if (!formData.message) {
        errors.message = "お問い合わせ内容を入力してください";
        isValid = false;
      } else {
        errors.message = "";
      }

      if (!formData.consent) {
        errors.consent = "個人情報の取り扱いに同意してください";
        isValid = false;
      } else {
        errors.consent = "";
      }

      return isValid;
    };

    const handleSubmit = (event) => {
      event.preventDefault();

      if (validateForm()) {
        router.push({
          path: "/confirmation",
          query: {
            ...formData,
          },
        });
      }
    };

    return {
      formData,
      errors,
      handleSubmit,
    };
  },
};
</script>

<style scoped>
.error {
  color: #f00;
}

.checkbox-container {
  gap: 10px;
  padding-bottom: 25px;
}

.textbox {
  display: flex;
  align-items: center;
  width: 39vw;
  max-width: 100%;
  border: none;
  margin-bottom: 3rem;
  padding: 1rem 0.5rem;
  border-radius: 0.3rem;
  background-color: #f1f3f7;
}

.checkbox-item {
  display: flex;
  gap: 8px; /* チェックボックスとラベル間の間隔 */
}

input[type="checkbox"] {
  margin: 0;
  width: auto;
}

.top-contact-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  font-family: "Cormorant Garamond", serif;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}

.contactform-containr {
  margin: 10% auto;
  min-width: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactform-text {
  width: 60%;
  font-size: 15px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e1e1;
}

p {
  font-size: 13px;
}

form {
  width: 60%;
  margin-top: 50px;
  margin-bottom: 100px;
}

textarea {
  height: 25rem;
}

a {
  color: #000;
}

.nextbutton {
  display: block;
  width: 30%;
  height: 80px;
  margin: auto;
  font-size: 14px;
  color: #fff;
  background-color: #00417f;
  margin-top: 40px;
}

.nextbutton:hover {
  background-color: #00274d;
}

@media screen and (max-width: 1000px) {
  .contactform-text {
    width: 80%;
  }

  form {
    width: 80%;
  }
  .textbox {
    width: 100%;
  }
  .nextbutton {
    width: 250px;
  }
}
</style>
