<template>
  <div>
    <div class="top-company-heading">
      <h1>Company</h1>
    </div>

    <div class="greetings-president-container" id="greetings-president">
      <div class="greetings-president">
        <h5>「日本を代表するセキュリティサポートカンパニーを目指して」</h5>
        <div class="greetings-president-message">
          <p>
            私たちは創業以来、認証コンサルティングという分野において日本国内だけでなく
            全世界を対象に最新情報の収集とコンサルティングスキルの向上に努めてまいりました。<br />
            今後も、一切妥協を許さぬ姿勢でお客様ごとの状況や
            ビジネススタイルに合わせたコンサルティングサービスを提供してまいります。
          </p>
          <p>
            これからの社会において、企業による情報セキュリティの取り組みは必要不可欠となっております。<br />
            ただし、法律や規格が複雑に絡み合う情報セキュリティは様々な専門知識が必要であるため、
            すべての取り組みを自社内で構築し、運用していくことは困難です。
          </p>
          <p>
            一般的に、コンサルティングとは専門知識をもとに
            「現状分析」「改善提案」「改善計画立案」といった支援を想像されるのではないでしょうか。<br />
            しかし、企業が求めるのは提案で終わるのではなく、提案の先にある具体的な活動に対する"結果"を伴う取り組みです。
          </p>
          <p>
            したがって私たちは、現場に入り実務面でのコンサルティングまで行うことで、
            企業がより少ない負荷で課題解決するための効率的な支援をいたします。<br />
            私たちのサービスは「外部セキュリティ担当者」ではなく、
            最適提案/費用対効果を心掛けた「情報セキュリティにおけるビジネスパートナー」を目指し続けます。
          </p>
          <p>
            私たちが掲げている「顧客に新たな成功をもたらし、継続的な企業価値の発展向上に貢献します。」というMISSIONのもと、
            提供しているサービスがお客様の成功/成長の一助となるよう、社員一同邁進させていただく所存でございます。
          </p>
        </div>
        <div class="greetings">
          <p>令和6年7月</p>
          <p>株式会社UPF</p>
          <p>代表取締役社長 /仲手川啓</p>
        </div>
      </div>
      <div class="president-img">
        <img src="@/static/president.jpg" alt="社長" />
      </div>
    </div>

    <div class="company-profile-container" id="company-profile">
      <div class="company-profile-heading">
        <h4>About us</h4>
      </div>
      <div class="company-introduction">
        <div class="company-details">
          <div class="detail-title">
            <p>会社名</p>
          </div>
          <div class="detail-describe">
            <p>株式会社UPFF（ユーピーエフ）</p>
          </div>
        </div>

        <div class="company-details">
          <div class="detail-title">
            <p>設立</p>
          </div>
          <div class="detail-describe">
            <p>2005年5月</p>
          </div>
        </div>

        <div class="company-details">
          <div class="detail-title">
            <p>資本金</p>
          </div>
          <div class="detail-describe">
            <p>1,000万円</p>
          </div>
        </div>

        <div class="company-details">
          <div class="detail-title">
            <p>役員</p>
          </div>
          <div class="detail-describe">
            <p>代表取締役&emsp;仲手川 啓</p>
            <p>&emsp;&emsp;取締役&emsp;橋本 裕二</p>
          </div>
        </div>

        <div class="company-details">
          <div class="detail-title">
            <p>事業内容</p>
          </div>
          <div class="detail-describe">
            <p>・情報セキュリティコンサルティング</p>
            <p>・リスク・クライシスマネジメントコンサルティング</p>
            <p>
              ・プライバシーマーク新規取得・更新・運用コンサルティングに付帯する関連サービス
            </p>
            <p>
              ・IISO27001（ISMS）新規取得・更新・運用コンサルティングに付帯する関連サービス
            </p>
            <p>
              ・ISO27017（クラウドセキュリティ）新規取得・更新・運用コンサルティングに付帯する関連サービス
            </p>

            <p>
              ・ISO27017（クラウドセキュリティ）新規取得・更新・運用コンサルティングに付帯する関連サービス
            </p>
            <p>・GDPR対応支援サービス</p>
            <p>・TISAX認証取得支援サービス</p>
            <p>・ISMAP登録支援サービス</p>
            <p>・PCI-DSS準拠支援サービス</p>

            <p>・個人情報保護、情報セキュリティ研修事業</p>
            <p>・情報セキュリティ監査代行</p>
          </div>
        </div>
        <div class="company-details">
          <div class="detail-title">
            <p>取引銀行</p>
          </div>
          <div class="detail-describe">
            <p>朝日信用金庫豊島町支店</p>
            <p>東京シティ信用金庫秋葉原支店</p>
            <p>みずほ銀行日本橋支店</p>
            <p>三井住友銀行五反田支店</p>
          </div>
        </div>

        <div class="company-details">
          <div class="detail-title">
            <p>所属団体</p>
          </div>
          <div class="detail-describe">
            <p>東京商工会議所</p>
            <p>マルチメディア情報協同組合</p>
            <p>アジア経営者連合会</p>
            <p>EO TOKYO</p>
            <p>芝ライオンズクラブ</p>
            <p>エンジェル投資家コミュニティ SEVEN</p>
            <p>一般社団法人プライバシーマーク認証コンサルタント協会（PCA）</p>
            <p>一般社団法人人材ビジネス協会</p>
          </div>
        </div>

        <div class="company-details">
          <div class="detail-title">
            <p>取得認証</p>
          </div>
          <div class="detail-describe">
            <p>
              プライバシーマーク：認定番号 10862212(06)(一般財団法人
              日本情報経済社会推進協会)
            </p>
            <p>ISMS（ISO/IEC 27001）:登録証番号:JQA-IM1654</p>
            <p>ISO/27701 登録番号:JQA-PI0001</p>
            <img src="" alt="" />
            <img src="" alt="" />
            <img src="" alt="" />
          </div>
        </div>
        <div class="company-details">
          <div class="detail-title">
            <p>事業許可番号</p>
          </div>
          <div class="detail-describe">
            <p>労働者派遣事業 派13ｰ317616</p>
            <p>有料職業紹介事業 許可番号13-ユ-317329</p>
          </div>
        </div>

        <div class="company-details">
          <div class="detail-title">
            <p>CSR</p>
          </div>
          <div class="detail-describe">
            <a href="https://www.b4s.jp/">ブリッジフォースマイル</a>
            <a href="https://leavehome.org/"
              >一般社団法人日本児童養護施設財団</a
            >
            <a href="https://nippon-smes-project.com/sdgs/"
              >中小企業から日本を元気にプロジェクト</a
            >
          </div>
        </div>
        <div class="company-details">
          <div class="detail-title">
            <p>拠点</p>
          </div>
          <div class="detail-describe">
            <div class="company-location">
              <h5>東京本部（東日本コンサルティングセンター）</h5>
              <p>〒103-0001</p>
              <p>東京都中央区日本橋小伝馬町2-4 三報ビルディング5階</p>
            </div>
            <div class="company-location">
              <h5>東日本第二営業所</h5>
              <p>〒103-0001</p>
              <p>東京都中央区日本橋小伝馬町9-2 石田ビル3階</p>
            </div>
            <div class="company-location">
              <h5>名古屋営業所（中部コンサルティングセンター）</h5>
              <p>〒460-0008</p>
              <p>愛知県名古屋市中区栄3丁目2番3号 名古屋日興證券ビル4階</p>
            </div>
            <div class="company-location">
              <h5>大阪営業所（関西コンサルティングセンター）</h5>
              <p>〒532-0011</p>
              <p>大阪府大阪市淀川区西中島3-18-21 NLC新大阪18号館2階</p>
            </div>
            <div class="company-location">
              <h5>福岡営業所（九州コンサルティングセンター）</h5>
              <p>〒812-0038</p>
              <p>福岡県福岡市博多区祇園町8-13 第一プリンスビル2階</p>
            </div>
          </div>
        </div>
        <div class="company-details">
          <div class="detail-title">
            <p>連絡先</p>
          </div>
          <div class="detail-describe">
            <p>本社電話番号: 03-6661-0846</p>
            <p>※お電話は本社にて一括でお受けさせて頂く形となります。</p>
          </div>
        </div>
        <div class="company-details">
          <div class="detail-title">
            <p>インボイス制度</p>
          </div>
          <div class="detail-describe">
            <p>名称: 株式会社UPF</p>
            <p>登録番号: T5010001228031</p>
          </div>
        </div>
      </div>
    </div>

    <div class="company-History-container">
      <div class="company-History-heading">
        <h4>History</h4>
      </div>
      <ul class="company-History-details">
        <li class="company-event">
          <span class="year">2003</span>
          <h3>2003年5月</h3>
          <p>
            個人事業主としてDM発送とデータベースマーケティング支援事業「上野企画」を創業（後に法人化）
          </p>
        </li>
        <li class="company-event">
          <span class="year">2005</span>
          <div class="company-event-details">
            <h3>2005年7月</h3>
            <p>
              DM発送代行事業のクライアント向けにプライバシーマーク取得・運用代行サービスを開始。
            </p>
          </div>
        </li>
        <li class="company-event">
          <span class="year">2008</span>
          <h3>2008年8月</h3>
          <p>
            プライバシーマーク/ISMSの新規取得・更新支援をメインとした情報セキュリティー専門認証支援事業を本格開始。
          </p>
        </li>
        <li class="company-event">
          <span class="year">2016</span>
          <h3>2016年7月</h3>
          <p>コンサルティングセンターとして名古屋・大阪・福岡営業所開設。</p>
        </li>
        <li class="company-event">
          <span class="year">2018</span>
          <h3>2018年6月</h3>
          <p>GDPR対応構築支援事業を開始</p>
        </li>
        <li class="company-event">
          <span class="year">2022</span>
          <h3>2022年2月</h3>
          <p>TISAX構築支援サービスを開始</p>
        </li>
        <li class="company-event">
          <h3>2022年5月</h3>
          <p>本社を中央区日本橋小伝馬町に移転しました</p>
        </li>
        <li class="company-event">
          <h3>2022年7月</h3>
          <p>中小企業からニッポンを元気にプロジェクトへ参画</p>
        </li>
        <li class="company-event">
          <span class="year">2023</span>
          <h3>2023年4月</h3>
          <p>
            プライバシーマーク、ISMS取得支援社数の累計が3000社を突破しました
          </p>
        </li>
        <li class="company-event">
          <h3>2023年7月</h3>
          <p>福岡営業所を移転しました</p>
        </li>
        <li class="company-event">
          <span class="year">2024</span>
          <h3>2024年10月</h3>
          <p>東日本第二営業所を開設しました（本社から徒歩５分圏内）</p>
        </li>
        <li class="company-event">
          <h3>2024年12月</h3>
          <p>
            プライバシーマーク、ISMS取得支援社数の累計が7500社を突破しました
          </p>
        </li>
      </ul>
    </div>
    <ContactSection />
  </div>
</template>

<script>
import ContactSection from "@/components/organisms/ContactSection.vue";

export default {
  name: "CompanyPage",
  components: {
    ContactSection,
  },
};
</script>

<style scoped>
.top-company-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  font-family: "Cormorant Garamond", serif;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}

p,
a {
  font-family: "Noto Serif JP", serif;
}

.greetings-president-container {
  height: 1300px;
  margin-bottom: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #f0f3f7;
}

.greetings-president {
  width: 55%;
  padding: 20px;
  margin-bottom: 300px;
  background-color: #fff;

  & h5 {
    padding: 30px;
    font-weight: 600;
  }
}

.greetings-president-message {
  margin: 40px;
  padding-bottom: 20px;
  /* margin-bottom: 40px; */
  & p {
    font-size: 14px;
    font-weight: 100;
  }
}

.greetings {
  line-height: 7px;
  font-size: 15px;
  padding-right: 40px;
  float: right;
}

.president-img img {
  width: 30vw;
  padding-top: 5%;
  padding-left: 5%;
  margin-bottom: 300px;
  background-color: #fff;
}

.company-profile-container {
  display: flex;
  margin-top: 150px;
}

.company-profile-heading,
.company-History-heading {
  margin-top: 30px;
  & h4 {
    margin-left: 20%;
    font-size: 30px;
    color: #00417f;
    font-family: "Cormorant Garamond", serif;
  }
}

.company-profile-heading {
  width: 35%;
}

.company-introduction {
  width: 60%;
  & p {
    margin: 0.3em;
  }
}

.company-details {
  display: flex;
  padding: 50px 0;
  margin-bottom: 20px;
  font-size: 14px;
  border-bottom: 1px solid #e2e1e1;
}

.detail-title {
  width: 20%;
  color: #757575;
}

.detail-describe a {
  display: block;
  color: #000;
}

.company-location {
  margin-bottom: 40px;
}

.company-location h5 {
  font-size: 15px;
}

.company-History-container {
  display: flex;
  margin-top: 200px;
  padding-bottom: 120px;
  padding-top: 150px;
  background-color: #f8f8ff;
}

.company-History-heading {
  width: 45%;
}

.company-History-details {
  list-style: none;
  margin: 40px auto 0 6em;
  border-left: 6px solid #00417f;
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  width: 80%;
  vertical-align: middle;
}

.company-History-details .year {
  width: 5em;
  display: inline-block;
  margin-left: -1.5em;
  margin-bottom: 20px;
  padding: 0 0 5px;
  top: -0.4em;
  font-size: 40px;
  font-weight: bold;
  vertical-align: top;
  position: relative;
  text-align: left;
  color: #00417f;
}

.company-History-details .year::after {
  content: "";
  position: absolute;
  right: 235px;
  top: 0.4em;
  background: #00417f;
  width: 20px;
  height: 20px;
  /* border-radius: 10px; */
}

.company-event {
  margin-left: 70px;
  margin-bottom: 70px;
  & h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .greetings-president-container {
    height: 1300px;
    margin-bottom: 200px;
  }

  .greetings-president {
    width: 65%;
    margin-top: 150px;

    & h5 {
      font-size: 17px;
    }
  }

  .greetings-president-message {
    margin: 30px;
    padding-bottom: 10px;
    & p {
      font-size: 14px;
    }
  }

  .greetings {
    font-size: 14px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 750px) {
  /* .top-company-heading {
    margin-bottom: 150px;
  } */

  .greetings-president-container {
    height: 1600px !important;
    display: block !important;
    padding-top: 200px;
    margin-bottom: 50px;
  }

  .greetings-president {
    width: 90%;
    min-height: 70vh;
    max-height: 100vh;
    margin-left: 5%;
    margin-top: 0;
    margin-bottom: 0;
    /* border: 1px solid #000; */

    & h5 {
      font-size: 14px;
    }
  }

  .greetings-president-message {
    margin: 30px;
    padding-bottom: 0px;

    & p {
      font-size: 11px;
    }
  }

  .greetings {
    font-size: 12px;
    padding-left: 30px;
    float: left;
  }
  .president-img img {
    width: 90%;
    margin-left: 5%;
    padding-top: 0;
    margin-top: 0;
  }

  .company-profile-container,
  .company-History-container {
    display: inline-block;
  }

  .company-profile-container {
    margin-top: 0;
  }

  .company-introduction {
    width: 90%;
    margin-left: 5%;
  }
  .detail-title {
    width: 25%;
  }
  .detail-describe {
    width: 75%;
  }

  .company-event {
    width: 60%;
  }

  /* .company-event-details {
    width: 70%;
    border: 1px solid #000;
  } */

  /* .company-event h3,
  .company-event p {
    margin-left: 0;
    border: 1px solid #000;
  } */

  .company-event p {
    font-size: 13px;
  }
}
</style>
