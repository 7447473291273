<template>
  <div>
    <div class="top-recruit-heading">
      <h1>Recruit</h1>
    </div>
    <div class="recruit-top-container">
      <div class="recruit-top-img">
        <img src="@/static/logo.png" alt="ロゴ" />
      </div>
      <div class="recruit-top-text">
        <p>
          私たちUPFは、<br />
          情報セキュリティコンサルティングを基軸とする<br />
          プロフェッショナル集団です。<br />
          2005年の創業以来、UPFは東京本社を中心に、<br />
          名古屋、大阪、福岡に営業所を展開し、<br />
          全国規模でサービスを提供しています。<br />
          売上高は5年連続前年比150%増で事業拡大中。<br />
          特にプライバシーマークや<br />
          ISMSの新規取得・更新支援事業では、<br />
          累計支援社数が約5,000社を超える実績を持ち、<br />
          多くの企業から信頼を得ています。
        </p>
      </div>
    </div>
    <div class="business-container">
      <div class="business-heading">
        <h2>Business</h2>
      </div>
      <div class="business-detail">
        <div class="business-detail-container">
          <h3>
            情報セキュリティ<br class="sp" />
            コンサルティング事業
          </h3>
          <p class="security-detail-container">
            当社は、情報セキュリティに特化したコンサルティング会社として、<wbr />
            個人情報保護法や各省庁が発行しているガイドラインに基づく<wbr />
            *Pマーク（プライバシーマーク）*や<wbr />
            *ISMS（情報セキュリティマネジメントシステム）*の<wbr />
            新規取得・運用サポート・更新支援を中心に、企業の信頼性と<wbr />
            安全性を高めるための幅広いサービスを提供しています。<wbr />
            お客様の課題に寄り添い、最適なリスクマネジメントを提案することで、<wbr />
            業界を問わず多くの企業様から信頼をいただいております
          </p>
        </div>
        <div class="business-detail-container">
          <h3>サービス内容</h3>
          <ul>
            <li>
              プライバシーマーク新規取得・更新・運用コンサルティングに付帯する関連サービス
            </li>
            <li>
              ISO27001（ISMS）新規取得・更新・運用コンサルティングに付帯する関連サービス
            </li>
            <li>
              ISO27017（クラウドセキュリティ）新規取得・更新・運用コンサルティングに付帯する関連サービス
            </li>
            <li>
              各種ISO（9001、14001等）新規取得・更新・運用コンサルティングに付帯する関連サービス
            </li>
            <li>GDPR対応支援サービス</li>
            <li>TISAX認証取得支援サービス</li>
            <li>ISMAP登録支援サービス</li>
            <li>PCI-DSS準拠支援サービス</li>
            <li>個人情報保護、情報セキュリティ研修事業</li>
            <li>情報セキュリティ監査代行</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="people-container">
      <div class="peoplesection-img">
        <img src="@/static/peopleSectionImg.jpg" alt="people" />
      </div>
      <div class="people-heading">
        <h2>People</h2>
        <div class="mt-5">
          <ViewMoreButton link="#/People" />
        </div>
      </div>
    </div>

    <div class="mt-100 company-photo">
      <div ref="carouselElement" class="carousel slide">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="@/static/upfcmpanytop.jpeg"
              class="d-block w-100"
              alt="upfcmpanytop"
            />
          </div>
          <div class="carousel-item">
            <img
              src="@/static/upfcmpany2.jpeg"
              class="d-block w-100"
              alt="office"
            />
          </div>
          <div class="carousel-item">
            <img
              src="@/static/upfcmpany3.jpeg"
              class="d-block w-100"
              alt="entrance"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="environment-container">
      <h2>Environment</h2>
      <div>
        <ViewMoreButton link="#/environment" />
      </div>
    </div>
    <div class="entry-container">
      <h2>Entry</h2>
      <div>
        <div>
          <a
            href="#/NewGraduateHiring"
            class="form-entry d-flex align-items-center mx-5"
          >
            <i class="mail-img bi bi-building-fill-add m-4"></i>
            <p class="entry-form-text">新卒採用</p>
          </a>
        </div>
        <div>
          <a
            href="#/midcareerHiring"
            class="form-entry d-flex align-items-center mx-5"
          >
            <i class="mail-img bi bi-building-fill-add m-4"></i>
            <p class="entry-form-text">中途採用</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ViewMoreButton from "../atoms/ViewMoreButton.vue";
import { ref, onMounted, onUnmounted } from "vue";
import { Carousel } from "bootstrap";

const carouselElement = ref(null);
let carouselInstance = null;

onMounted(() => {
  if (!carouselElement.value) return;

  // Intersection Observer 設定
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // `data-bs-ride="carousel"` を設定
          carouselElement.value.setAttribute("data-bs-ride", "carousel");

          // 既存のインスタンスがある場合は破棄
          if (carouselInstance) {
            carouselInstance.dispose();
          }

          // 再初期化してスライド開始
          carouselInstance = new Carousel(carouselElement.value, {
            interval: 3000,
            pause: false,
            ride: "carousel",
          });

          observer.disconnect(); // 1回実行後に監視を解除
        }
      });
    },
    { threshold: 0.5 } // 50%以上見えたら発火
  );

  observer.observe(carouselElement.value);
});

onUnmounted(() => {
  if (carouselInstance) {
    carouselInstance.dispose(); // クリーンアップ
  }
});
</script>

<style scoped>
.top-recruit-heading {
  height: 450px;
  display: flex;
  align-items: center;
  padding-left: 6%;
  padding-top: 8%;
  font-family: "Cormorant Garamond", serif;
  border-bottom: 1px solid #e2e1e1;

  & h1 {
    font-size: clamp(20px, 50px, 70px);
  }
}

.recruit-top-container {
  margin-top: 150px;
  margin-bottom: 200px;
  display: flex;
  align-items: center;
}

.recruit-top-img {
  width: 50%;
}
.recruit-top-img img {
  width: 300px;
  margin-left: 20%;
}

.recruit-top-text p {
  line-height: 2.3;
  font-weight: bold;
}

.business-heading {
  height: 300px;
  display: flex;
  align-items: center;
  background-color: aliceblue;
}

.business-heading h2 {
  margin-left: 10%;
}

.business-detail {
  width: 100%;
  background-size: cover;
  object-fit: cover;
  display: flex;
}

.business-detail-container {
  width: 50%;
  height: 700px;
  text-align: center;
  color: #fff;
  background-color: #00417f;
}

.business-detail-container:first-child {
  background-color: #00274d;
}

.business-detail-container h3 {
  height: 120px;
  margin-top: 50px;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: bold;
}

.business-detail-container p,
.business-detail-container ul {
  margin: 0 60px;
  text-align: left;
  word-break: keep-all;
}

.business-detail-container p {
  display: inline-block;
  /* border: 1px solid #fff; */
}

.business-detail-container ul {
  width: 70%;
  margin: auto;
  /* border: 1px solid #fff; */
}

.sp {
  display: none;
}

.people-container {
  height: 1200px;
  display: flex;
  align-items: center;
}

.peoplesection-img {
  width: 60%;
}

.peoplesection-img img {
  width: 100%;
}

.people-heading {
  margin-left: 10%;
}

.company-photo {
  width: 90%;
  margin: auto;
}

.company-photo img {
  height: auto;
}

.environment-container {
  width: 80%;
  height: 300px;
  margin-left: 0;
  margin-bottom: 100px;
  position: relative;
  top: -100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  box-shadow: 20px 20px 0 rgba(97, 116, 141, 0.25);
}

.entry-container {
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-image: url("@/static/carousel-inner2.jpg");
}

.entry-container h2 {
  color: #fff;
}

.form-entry {
  width: 300px;
  height: 80px;
  margin-bottom: 30px;
  color: #fff;
  border: 1px solid #fff;
  z-index: 2;
  position: relative;
  display: inline-block;
  transition: color 0.25s ease;

  &:hover {
    color: #00274d;
    .mail-img {
      border-right: 1px solid #00274d;
    }
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: right;
    transition: all 0.5s ease;
    transition-property: transform;
    z-index: -1;
  }
}

.mail-img {
  font-size: 22px;
  padding-left: 5%;
  padding-right: 15%;
  line-height: 1;
  border-right: 1px solid #fff;
  z-index: 1;
}

.entry-form-text {
  margin-top: 18px;
  margin-left: 18px;
  z-index: 2;
  font-family: "Cormorant Garamond", serif;
  text-align: center;
  font-size: 100%;
}

@media screen and (max-width: 1400px) {
  .sp {
    display: block;
  }
}

@media screen and (max-width: 1000px) {
  .recruit-top-container {
    display: flex;
    flex-direction: column;
  }

  .recruit-top-img img {
    margin: auto;
  }

  .recruit-top-text {
    margin-top: 100px;
  }

  .business-detail {
    display: flex;
    flex-direction: column;
  }

  .business-detail-container {
    width: 100%;
  }

  .business-detail-container:first-child {
    height: 600px;
  }

  .people-container {
    display: flex;
    flex-direction: column;
  }

  .peoplesection-img {
    width: 90%;
    margin-top: 250px;
    margin-bottom: 50px;
    margin-right: auto;
  }

  .environment-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .environment-container h2 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 800px) {
  .peoplesection-img {
    width: 95%;
  }

  .company-photo {
    width: 100%;
  }

  .environment-container {
    width: 90%;
    height: 250px;
  }

  .entry-container {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .recruit-top-img {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .business-heading {
    height: 200px;
  }

  .business-detail-container {
    height: 600px;
  }

  .business-detail-container:first-child {
    height: 550px;
  }

  .business-detail-container p,
  .business-detail-container ul {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 12.5px;
  }

  .business-detail-container h3 {
    font-size: 30px;
    margin-bottom: 40px;
  }

  .people-container {
    height: 80vh;
  }

  .peoplesection-img {
    margin-top: 100px;
  }

  .environment-container {
    height: 200px;
    position: relative;
    top: -30px;
  }

  .environment-container h2,
  .people-heading h2,
  .business-heading h2 {
    font-size: 50px;
  }
}
</style>
